
	import { Component, Vue, Prop } from 'vue-property-decorator';

	@Component({
		name: 'CheckBox',
	})
	export default class CheckBox extends Vue {
		@Prop() readonly label: string;
		@Prop() readonly checked: boolean;
		@Prop() readonly disabled: boolean;
		@Prop({ default: 'md' }) readonly size: 'sm' | 'md' | 'lg';
		@Prop() readonly name: string;
		@Prop() readonly value: string;
		@Prop() readonly id: string;

		get className() {
			let className = `checkbox-container checkbox-container--${this.size}`;
			if (this.disabled) className += ' disabled';
			if (this.checked) className += ' checkbox-checked';
			return className;
		}

		handleClick(e) {
			e.target.checked = this.checked;
		}
	}
