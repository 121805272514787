
	import { Component, Vue, Prop } from 'vue-property-decorator';

	import IconArrowRight from '@pixcap/ui-library/components/Icons/IconArrowRight.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	@Component({
		name: 'BreadCrumb',
		components: {
			IconArrowRight,
			BodyText,
		},
		computed: {},
	})
	export default class BreadCrumb extends Vue {
		@Prop({
			default() {
				return [];
			},
		})
		breadCrumb: any[];

		get breadcrumbSize() {
			return this.breadCrumb.length - 1;
		}

		handleChange(index) {
			if (index === this.breadcrumbSize) return;
			this.$emit('onChange', index);
		}
	}
