
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import IconClose from './Icons/IconClose.vue';
	@Component({
		name: 'BaseModal',
		components: {
			IconClose,
		},
	})
	export default class BaseModal extends Vue {
		@Prop() containerStyle: any; // style for modal container
		@Prop() className: string; // class name for modal container
		@Prop() allowOverflow: boolean;
		@Prop() showCloseButton: boolean;

		handleClose(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$emit('onClose');
		}
	}
