
	import { Component, Vue } from 'vue-property-decorator';

	@Component({
		name: 'SymboleLogo',
	})
	export default class SymboleLogo extends Vue {
		uniqueIdentifier = new Date().getTime();
		clipPathId = `clip-path-${this.uniqueIdentifier}`;
		paint0Id = `paint0_linear_${this.uniqueIdentifier}`;
		paint1Id = `paint1_linear_${this.uniqueIdentifier}`;
		paint2Id = `paint2_linear_${this.uniqueIdentifier}`;
		paint3Id = `paint3_linear_${this.uniqueIdentifier}`;
		paint4Id = `paint4_linear_${this.uniqueIdentifier}`;
		paint5Id = `paint5_linear_${this.uniqueIdentifier}`;
		paint6Id = `paint6_linear_${this.uniqueIdentifier}`;
		paint7Id = `paint7_linear_${this.uniqueIdentifier}`;
		paint8Id = `paint8_linear_${this.uniqueIdentifier}`;
	}
