
	import { Vue, Component, Prop } from 'vue-property-decorator';

	@Component({
		name: 'AspectRatioThumbnail',
	})
	export default class AspectRatioThumbnail extends Vue {
		@Prop() thumbnailUrl: string;
		@Prop() thumbnailAlt: string;
	}
